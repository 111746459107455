import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { navigate } from "gatsby-link";
import { ToastContainer, toast, Slide } from "react-toastify";
import firebase from "../../config/firebase";

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider";

import AppLayout from "../components/appLayout";

// markup
const Dashboard = () => {
  const dispatch = React.useContext(GlobalDispatchContext);
  const state = React.useContext(GlobalStateContext);

  const [allowedIps, setAllowedIps] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [savingIps, setSavingIps] = useState(false);

  const [ip, setIp] = useState();

  // Make sure user is logged in
  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        setLoadingData(false);
      } else {
        navigate("/login");
      }
    });
  }, []);

  useEffect(() => {
    if (state.userToken) {
      axios
        .get(`${process.env.GATSBY_API_BASE}/ip`, {
          headers: {
            Authorization: `Bearer ${state.userToken}`,
          },
        })
        .then((response) => {
          setIp(response.data);
        })
        .catch(() => {
          console.log("Unable to get IP");
        });
    }
  }, [state.userToken]);

  const handleSaveWhitelistedIps = () => {
    setSavingIps(true);
    axios
      .post(
        `${process.env.GATSBY_API_BASE}/ip-auth`,
        {
          allowedIps,
        },
        {
          headers: {
            Authorization: `Bearer ${state.userToken}`,
          },
        }
      )
      .then(() => {
        // Refresh user profile so we have latest data
        dispatch({
          type: "REFRESH_USER",
          refreshUser: true,
        });

        // Show success toast
        toast.success("IP address saved", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          // Show error toast
          toast.error(err.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .finally(() => {
        setSavingIps(false);
      });
  };

  return (
    <>
      {!loadingData && (
        <AppLayout>
          <Helmet>
            <title>Whitelist IP</title>
          </Helmet>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            transition={Slide}
          />
          <div className="py-10">
            <header>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold leading-tight text-gray-900">
                  Whitelisted IPs
                </h1>
                <p className="mt-2 max-w-2xl text-md text-gray-500">
                  We allow authentication via your Proxy username + password.{" "}
                  <br />
                  You can also authenticate by adding your IP addresses below.
                </p>
              </div>
            </header>
            <main>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                {/* Replace with your content */}
                <div className=" py-8 sm:px-0">
                  <div className="p-8 border border-gray-200 rounded-lg bg-white">
                    <div className="max-w-xl">
                      <div>
                        <label
                          htmlFor="search"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Your IP Address
                        </label>
                        <div className="mt-1 relative flex items-center">
                          <input
                            type="text"
                            name="search"
                            id="search"
                            disabled={true}
                            value={ip}
                            className="outline-none p-3 shadow-sm border focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md bg-gray-100 "
                          />
                          {/* <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                            <kbd className="bg-white cursor-pointer inline-flex items-center border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-400">
                              Copy to clipboard
                            </kbd>
                          </div> */}
                        </div>
                      </div>

                      <div className="my-5">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Whitelisted IPs
                        </label>
                        <div className="mt-1">
                          <textarea
                            id="email"
                            className="outline-none p-3 shadow-sm border focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            rows={5}
                            defaultValue={state.user?.whitelistedIps.join("\n")}
                            onChange={(e) => {
                              let ips = e.target.value.split("\n");

                              setAllowedIps(ips);
                            }}
                          ></textarea>
                        </div>
                        <p className="mt-2 text-sm text-gray-500">
                          Please add one IP address per line. You have added{" "}
                          <strong>
                            {state.user ? state.user.whitelistedIps.length : 0}
                          </strong>{" "}
                          out of{" "}
                          <strong>
                            {state.user ? state.user.whitelistedIpsAllowed : 0}
                          </strong>{" "}
                          IPs allowed.
                        </p>
                      </div>

                      <div className="mt-4">
                        <button
                          type="button"
                          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={handleSaveWhitelistedIps}
                        >
                          {savingIps && (
                            <>
                              <svg
                                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                              Saving
                            </>
                          )}
                          {!savingIps && <>Save</>}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /End replace */}
              </div>
            </main>
          </div>
        </AppLayout>
      )}
    </>
  );
};

export default Dashboard;
